@import "../theme/index";

.article {
  margin-top: 11px;
  @include respond-to(largedesktop) {
    margin-top: 25px;
  }

  &__header {
    margin-bottom: 20px;
  }

  &__title {
    background-color: $grey;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  &__close-button {
    width: 45px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    @include respond-to(largedesktop) {
      font-size: 24px;
    }
  }

  &__heading {
    font-size: 18px;
    margin: 0;
    @include respond-to(tablets) {
      font-size: 24px;
      padding: 10px 20px;
    }
    @include respond-to(largedesktop) {
      font-size: 36px;
      padding: 10px 40px;
    }
  }

  &__props {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 15px 0;
    border-bottom: 1px solid $grey;
    @include respond-to(tablets) {
      margin: 0 30px;
    }
    @include respond-to(largedesktop) {
      margin: 0 50px;
    }
  }

  &__information {
    display: flex;
    align-items: center;
  }

  &__page-number {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    @include respond-to(tablets) {
      font-size: 24px;
    }
  }

  &__icon {
    height: 30px;
    width: 30px;
    margin-right: 20px;

    &-img {
      height: 100%;
      fill: $red;
    }

    &--reading-time {
      height: 35px;
      width: 35px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .gritx-btn {
      font-size: 18px;
      min-height: 30px;
      line-height: 30px;
      min-width: 180px;
    }
  }

  &__button-icon {
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: darken($white, 4%);
    }
  }

  &__body {
    padding: 10px;
    @include respond-to(tablets) {
      padding: 20px;
    }
    @include respond-to(largedesktop) {
      padding: 20px 50px;
    }
  }

  &__description {
    font-style: italic;
    margin-bottom: 10px;
    @include textSize();
  }

  &__image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    margin: 0 auto 10px;
    text-align: center;
    height: 300px;
    @include respond-to(tablets) {
      height: 400px;
    }
    @include respond-to(largedesktop) {
      height: 500px;
    }
    @include respond-to(fullHD) {
      height: 600px;
    }
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
  }

  &__content {
    @include textSize();

    p, li {
      margin-bottom: 10px;
    }

    img {
      max-width: 100%;
    }
  }
}
