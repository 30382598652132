@import "../theme/index";

.gritx-btn {
  min-height: 60px;
  font-size: 24px;
  line-height: 60px;
  width: 100%;
  text-align: center;

  border: none;
  transition: color 0.3s;
  cursor: pointer;

  &:hover:not(:disabled) {
    transition: color 0.3s;
    color: $white;
  }

  &:disabled {
    cursor: default;
    background-color: $grey;
  }

  // Primary variant styles
  &.gritx-btn-primary {
    background-color: $red;
    font-weight: bold;
    color: $black;

    &:active {
      background: darken($red, 6%);
      color: $white;
    }

    &:disabled {
      background-color: $grey;
      color: $alto;
    }
  }

  // Secondary variant styles
  &.gritx-btn-secondary {
    background-color: $semiDarkGrey;
    color: $white;

    &:hover:not(:disabled) {
      color: $black;
    }

    &:active {
      background-color: darken($semiDarkGrey, 6%);
    }

    &:disabled {
      background-color: $alto;
    }
  }

  // Light variant styles
  &.gritx-btn-light {
    background-color: transparent;
    color: $black;

    &:hover:not(:disabled) {
      background-color: $grey;
      color: $black;
    }

    &:active:not(:disabled) {
      background-color: darken($white, 8%);
      color: $black;
    }

    &:disabled {
      background-color: transparent;
    }
  }

  // Outline variant styles
  &.gritx-btn-outline {
    background-color: $white;
    border: solid 1px $alto;
    font-weight: 500;
    min-height: 40px;
    line-height: 38px;

    &:hover:not(:disabled) {
      background-color: $grey;
      color: $black;
    }

    &:active:not(:disabled) {
      background-color: darken($white, 8%);
    }

    &:disabled {
      background-color: $grey;
      color: $alto;
    }
  }
}
