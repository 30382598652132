@import "../theme/index";

.gritx-toast {
  display: flex;

  &__message {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $black;
    padding: 8px;
  }

  &__description {
    font-size: 14px;
    padding-right: 20px;
  }

  &__icon {
    background-color: $red;
    padding: 0 6px;
    z-index: 1;
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    padding-right: 20px;
  }

  &__button {
    padding: 0;
    margin: 14px 0 0 0;
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: $black;
    text-align: right;
    font-size: 14px;
  }
}

.Toastify {
  &__toast {
    border-radius: 0px;
    box-shadow: none;
    background-color: $grey;
    margin: 8px 0;
    padding: 0px;

    &-body {
      margin: 0;
      padding: 0;
    }

    outline: $alto solid 1px;
    outline-offset: -1px;
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    opacity: 0.2;
    color: $black;

    > svg {
      height: 20px;
      width: 20px;
    }
  }
}
