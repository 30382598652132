@import "components/theme/index";

.d-form {
  margin-bottom: 10px;

  &__field {
    border: none;
    border-bottom: 1px solid $black;
  }
}
