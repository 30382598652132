@import "../../theme/index";

.toggle-button {
  position: relative;
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  padding: 11px 0 9px 0;
  border: solid 1px $alto;
  cursor: pointer;
  user-select: none;
  margin: 4px;

  &__icon {
    width: 63px;
    height: 46px;
    margin: auto;
    margin-bottom: 7px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    font-size: 14px;
    color: $black;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
  }

  &--checked {
    background: $white;
  }

  &--checked::before {
    content: "";
    position: absolute;
    display: block;
    top: 82px;
    left: -1px;
    bottom: -1px;
    pointer-events: none;
    height: 7px;
    width: 90px;
    background-color: $lineGreen;
  }

  &:hover:not(&--checked) {
    background: darken($grey, 2%);
  }

  &:active:not(&--checked) {
    background: darken($grey, 4%);
  }
}
