@import "components/theme/index";

.d-input {
  margin-bottom: 10px;

  &__field {
    border: none;
    border-bottom: 1px solid $black;
    width: 260px;
  }
}
