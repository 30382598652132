@import "../theme/index";

.materials-share {
  &__header {
    display: flex;
    background-color: $grey;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include respond-to(tablets) {
      flex-direction: row;
    }
    @include respond-to(desktop) {
      padding: 0 50px;
      justify-content: flex-end;
    }

    &::before {
      content: ' ';
      background-color: $grey;
      position: absolute;
      height: 100%;
      width: 100vw;
      right: 100%;
      top: 0;
    }

    &::after {
      content: ' ';
      background-color: $grey;
      position: absolute;
      height: 100%;
      width: 100vw;
      left: 100%;
      top: 0;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 20px;
    @include respond-to(desktop) {
      margin: 0;
      font-size: 24px;
      padding-left: 5%;
    }
    @include respond-to(desktoplarge) {
      font-size: 28px;
    }
    @include respond-to(fullHD) {
      font-size: 36px;
    }

    &-wrapper {
      flex: 1 1;
    }
  }

  &__button.gritx-btn {
    width: 245px;
    margin-bottom: 10px;
    font-size: 16px;
    height: 60px;
    line-height: initial;
    border: 1px solid $alto;
    font-weight: bold;
    @include respond-to(desktop) {
      margin-bottom: 0;
    }
    @include respond-to(largedesktop) {
      font-size: 24px;
    }

    &-outline {
      background-color: $white;
      @include respond-to(desktop) {
        &:first-child {
          margin-right: 90px;
        }
      }
    }
  }

  &__create-button.gritx-btn {
    width: 311px;
  }

  &__body {
    @include respond-to(desktop) {
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__subject, &__form {
    height: 500px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    @include respond-to(desktop) {
      width: 48%;
    }
  }

  &__cards-title {
    text-transform: uppercase;
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 0;
    @include respond-to(tablets) {
      padding-left: 30px;
    }
    @include respond-to(desktop) {
      margin-top: 100px;
      font-size: 24px;
    }
    @include respond-to(desktoplarge) {
      font-size: 28px;
    }
    @include respond-to(fullHD) {
      font-size: 36px;
      padding-left: 35px;
    }
  }
}

.subject-selector {
  &__description {
    background-color: $grey;
    margin-top: 10px;
    flex: 1 1 auto;
    padding: 35px;
    font-size: 24px;
    color: $darkGrey;

    pre{
      white-space: initial;
      font-family: 'Open Sans', sans-serif;
    }
  }
}