@import "components/theme/index";

.text-box {
  display: flex;
  flex-direction: column;
  @include textSize;
  min-height: 9.5em;

  &__label {
    display: flex;
    flex-direction: column;

    &-text {
      margin-bottom: 10px;
    }
  }

  &__required {
    color: $darkRed;
  }

  &__field {
    padding: 10px;
    line-height: 1.5;
    resize: vertical;
    max-height: 500px;
    border: 1px solid $black;

    &--error{
      border-color: $darkRed;
    }
  }

  &__error {
    display: block;
    color: $darkRed;
    font-size: 16px;
  }
}